<template>
  <div>
    <el-row>
      <el-col>
        <el-button size="mini" @click="downInfoExp" :loading="btnLoading"
          >导出</el-button
        >
        <el-pagination
          :hide-on-single-page="page.total > 10 ? false : true"
          v-if="page.total > 0"
          @size-change="handleSizeChange1"
          @current-change="handleCurrentChange1"
          :current-page="page.current"
          :page-sizes="[10, 20, 50, 100]"
          :pager-count="5"
          :page-size="page.size"
          layout="total,prev, pager, next,sizes"
          :total="page.total"
          style="padding-right: 0px"
        ></el-pagination>
      </el-col>
      <el-col>
        <el-table
          :header-cell-style="{
            'background-color': '#f5f7fa',
          }"
          :data="InfoData"
          class="tabBorder custor is-top"
        >
          <el-table-column
            label="项目经理"
            width="150"
            prop="projectManager"
            v-if="chartType == 'company'"
          >
          </el-table-column>
          <el-table-column label="时间" width="150" prop="">
            <template slot-scope="scope">
              {{
                chartType !== "company"
                  ? scope.row.lastModifiedTime
                    ? scope.row.lastModifiedTime.substring(0, 10)
                    : "-"
                  : scope.row.performanceDate
                  ? scope.row.performanceDate
                  : "-"
              }}
            </template>
          </el-table-column>
          <el-table-column
            v-if="chartType !== 'company'"
            prop="amount"
            label="总业绩"
          ></el-table-column>
          <el-table-column
            prop="achievement"
            width="150"
            :label="chartType !== 'company' ? '实际业绩' : '业绩金额'"
          ></el-table-column>
          <el-table-column
            v-if="chartType !== 'company'"
            label="业绩创造者"
            :prop="
              chartType == 'offer' || chartType == 'daogang'
                ? 'achievementCreator'
                : 'nickName'
            "
            width="150"
          ></el-table-column>
          <el-table-column
            v-if="chartType !== 'company'"
            label="业绩类型"
            width="150"
            :prop="
              chartType == 'offer' || chartType == 'daogang'
                ? 'type'
                : 'roleName'
            "
          ></el-table-column>
          <el-table-column
            v-if="chartType !== 'company'"
            label="分配比例（个人）"
            width="160"
            prop="allocatePercentStr"
          >
            <template slot-scope="scope">
              {{
                scope.row.allocatePercentStr
                  ? scope.row.allocatePercentStr
                  : "-"
              }}
            </template>
          </el-table-column>
          <el-table-column v-if="chartType == 'company'" label="客户名称">
            <template #default="scope">
              <span class="tabHref" @click="toCustomer(scope.row.id)">{{
                scope.row.companyName
              }}</span>
            </template></el-table-column
          >
          <el-table-column label="项目名称" show-overflow-tooltip>
            <template #default="scope">
              <router-link
                class="tabHref"
                target="_blank"
                :to="{
                  path: '/project/details',
                  query: { id: scope.row.projectId },
                }"
                >{{ scope.row.projectName }}
              </router-link>
            </template></el-table-column
          >
          <el-table-column label="候选人" width="120">
            <template #default="scope">
              <span class="tabHref" @click="showDrawer(scope.row.resumeId)">{{
                scope.row.resumeName
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            v-if="chartType !== 'company'"
            prop="industryName"
            label="行业"
          >
            <template #default="scope">
              {{ scope.row.industryName ? scope.row.industryName : "-" }}
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          :hide-on-single-page="page.total > 10 ? false : true"
          v-if="page.total > 0"
          @size-change="handleSizeChange1"
          @current-change="handleCurrentChange1"
          :current-page="page.current"
          :page-sizes="[10, 20, 50, 100]"
          :pager-count="5"
          :page-size="page.size"
          layout="total,prev, pager, next,sizes"
          :total="page.total"
          style="padding-right: 0px"
        ></el-pagination>
      </el-col>
    </el-row>
    <el-drawer
      :visible.sync="drawerVisible"
      custom-class="drawerClass"
      direction="ttb"
      :modal="false"
      append-to-body
      :before-close="handleClose"
      destroy-on-close
    >
      <div slot="title" class="drawerTitle">
        <i class="drawIcon iconfont icon-kh_xq"></i>
        人才
      </div>
      <div class="drawerCon">
        <resumeDetails
          v-if="drawerVisible"
          :resumeDetailId="resumeId"
          :hover="hoverInfo"
          :repeatResume="repeatResume"
        ></resumeDetails>
      </div>
    </el-drawer>
    <el-drawer
      :visible.sync="customerDrawerVisible"
      custom-class="drawerClass"
      direction="ttb"
      :modal="false"
      append-to-body
      :before-close="handleClose"
    >
      <div slot="title" class="drawerTitle">
        <i class="drawIcon iconfont icon-kh_xq"></i>客户
      </div>
      <div class="drawerCon">
        <customer :customId="customId" :activeToInfo="activedToInfo"></customer>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import {
  analyzeCompanyRankDetail, //客户到岗业绩排行
  analyzeDetailInvoice, //开票业绩
  analyzeDetailOffer, //Offer-到岗业绩
  analyzeDetailAccount, //回款业绩
  analyzeDetailExportOffer,
  analyzeDetailExportInvoice,
  analyzeDetailExportAccount,
  analyzeExportCompanyRankDetail,
} from "../../api/api";
import { ApiBaseUrl } from "../../api/http";
import resumeDetails from "../resumeDetail";
import customer from "../customer/customerInfo";
export default {
  name: "performanceInfo",
  props: ["chartType", "filter", "emplyeeId", "sourceIds", "real","companyRankType"],
  components: { resumeDetails, customer },
  data() {
    return {
      drawerVisible: false,
      InfoData: [],
      resumeId: null,
      btnLoading: false,
      baseUrl: ApiBaseUrl,
      hoverInfo: { matchAll: true, majorSearch: false, hover: true },
      repeatResume: { type: "列表", valId: "" },
      page: {
        current: 1,
        size: 10,
        total: 0,
      },
      activedToInfo: "1",
      customerDrawerVisible: false,
      customId: "",
    };
  },
  created() {
    if (this.chartType == "offer" || this.chartType == "daogang") {
      this.loadDetailachieve();
    } else if (this.chartType == "account") {
      this.loadDetailAccount();
    } else if (this.chartType == "company") {
      this.loadDetailCompany();
    } else {
      this.loadDetailInvoice();
    }
  },
  methods: {
    //关闭抽屉
    handleClose() {
      this.drawerVisible = false;
      this.customerDrawerVisible = false;
    },
    //弹出抽屉
    showDrawer(id) {
      this.drawerVisible = true;
      this.resumeId = id;
    },
    loadDetailCompany() {
      //客户到岗业绩
      analyzeCompanyRankDetail({
        companyRankType:this.companyRankType,
        show: this.filter.show,
        companyId: this.emplyeeId,
        startDate: this.filter.time[0],
        endDate: this.filter.time[1],
        page: this.page,
        sourceType: this.filter.searchType,
        sourceIds: this.sourceIds,
      }).then((res) => {
        if (res.success) {
          this.InfoData = res.result.records;
          this.page.total = res.result.total;
        }
      });
    },
    loadDetailachieve() {
      //offer业绩详情
      analyzeDetailOffer({
        companyRankType:this.filter.companyRankType,
        searchId: this.emplyeeId,
        start: this.filter.time[0],
        end: this.filter.time[1],
        page: this.page,
        searchType: this.real ? this.filter.searchType : "顾问",
        chartType: this.chartType == "offer" ? "Offer业绩" : "实际到岗业绩",
      }).then((res) => {
        if (res.success) {
          this.InfoData = res.result.records;
          this.page.total = res.result.total;
        }
        this.listLoading1 = false;
      });
    },
    loadDetailInvoice() {
      //开票业绩详情
      analyzeDetailInvoice({
        show: this.filter.show,
        searchId: this.emplyeeId,
        start: this.filter.time[0],
        end: this.filter.time[1],
        page: this.page,
        searchType: this.real ? this.filter.searchType : "顾问",
      }).then((res) => {
        if (res.success) {
          this.InfoData = res.result.records;
          this.page.total = res.result.total;
        }
      });
    },
    loadDetailAccount() {
      //回款业绩详情
      analyzeDetailAccount({
        show: this.filter.show,
        searchId: this.emplyeeId,
        start: this.filter.time[0],
        end: this.filter.time[1],
        page: this.page,
        searchType: this.real ? this.filter.searchType : "顾问",
      }).then((res) => {
        if (res.success) {
          this.InfoData = res.result.records;
          this.page.total = res.result.total;
        }
      });
    },
    downInfoExp() {
      //导出
      this.btnLoading = true;
      let setData = false;
      let isconfirm = true;
      var int = setTimeout(function () {
        let that = this;
        setData = true;
        clearInterval(int);
        let div =
          "<p style='font-weight:bold'>后台处理中，稍后请在 " +
          "<a href='/tools/exportingRecords' style='color:#526ecc'>导出记录</a>" +
          " 获取文件</p>";
        if (isconfirm) {
          that.$globalHub
            .$confirm(div, "提示", {
              showCancelButton: false,
              showConfirmButton: false,
              customClass: "altCls",
              dangerouslyUseHTMLString: true,
            })
            .then(() => {})
            .catch(() => {});
        }
      }, 5000);
      if (this.chartType == "offer" || this.chartType == "daogang") {
        analyzeDetailExportOffer({
          searchId: this.emplyeeId,
          start: this.filter.time[0],
          end: this.filter.time[1],
          page: this.page,
          searchType: this.real ? this.filter.searchType : "顾问",
          chartType: this.chartType == "offer" ? "Offer业绩" : "实际到岗业绩",
        }).then((res) => {
          if (res.success) {
            if (!setData) {
              isconfirm = false;
              location.href = this.baseUrl + res.result;
            }
          }
          this.btnLoading = false;
        });
      } else if (this.chartType == "account") {
        analyzeDetailExportAccount({
          show: this.filter.show,
          searchId: this.emplyeeId,
          start: this.filter.time[0],
          end: this.filter.time[1],
          page: this.page,
          searchType: this.real ? this.filter.searchType : "顾问",
        }).then((res) => {
          if (res.success) {
            if (!setData) {
              isconfirm = false;
              location.href = this.baseUrl + res.result;
            }
          }
          this.btnLoading = false;
        });
      } else if (this.chartType == "company") {
        analyzeExportCompanyRankDetail({
          companyRankType:this.filter.companyRankType,
          show: this.filter.show,
          companyId: this.emplyeeId,
          startDate: this.filter.time[0],
          endDate: this.filter.time[1],
          page: this.page,
          sourceType: this.filter.searchType,
          sourceIds: this.sourceIds,
        }).then((res) => {
          if (res.success) {
            if (!setData) {
              isconfirm = false;
              location.href = this.baseUrl + res.result;
            }
          }
          this.btnLoading = false;
        });
      } else {
        analyzeDetailExportInvoice({
          show: this.filter.show,
          searchId: this.emplyeeId,
          start: this.filter.time[0],
          end: this.filter.time[1],
          page: this.page,
          searchType: this.real ? this.filter.searchType : "顾问",
        }).then((res) => {
          if (res.success) {
            if (!setData) {
              isconfirm = false;
              location.href = this.baseUrl + res.result;
            }
          }
          this.btnLoading = false;
        });
      }
    },
    handleCurrentChange1(val) {
      this.page.current = val;
      if (this.chartType == "offer" || this.chartType == "daogang") {
        this.loadDetailachieve();
      } else if (this.chartType == "account") {
        this.loadDetailAccount();
      } else if (this.chartType == "company") {
        this.loadDetailCompany();
      } else {
        this.loadDetailInvoice();
      }
    },
    handleSizeChange1(val) {
      //pageSize 改变时会触发
      this.page.size = val;
      this.page.current = 1;
      if (this.chartType == "offer" || this.chartType == "daogang") {
        this.loadDetailachieve();
      } else if (this.chartType == "account") {
        this.loadDetailAccount();
      } else if (this.chartType == "company") {
        this.loadDetailCompany();
      } else {
        this.loadDetailInvoice();
      }
    },
    //客户详情
    toCustomer(comId) {
      this.customerDrawerVisible = true;
      this.customId = comId;
    },
  },
};
</script>
